<template>
  <b-container fluid>
    <b-row cols="12">
      <b-col class="col-12" style="margin-top: 10px; margin-bottom: 10px">
        <b-card class="container-fluid">
          <b-row class="justify-content-between" style="margin-left: 5px">
            <b-row>
              <b-card-text
                class="text-left align-middle"
                style="margin: auto;"
                text-tag="h3"
              >物品借用
              </b-card-text>
            </b-row>
            <b-row class="align-self-center">
              <b-input-group prepend="搜尋">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="search.selected"
                    :options="search.options"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="search.word"
                  type="text"
                />
                <b-input-group-append>
                  <b-button
                    variant="dark"
                    @click="search.word = ''"
                  >重設
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-row>
            <b-button-group>
              <b-button
                :variant="mode.safe ? 'danger' : 'primary'"
                size="lg"
                @click="mode.safe = !mode.safe"
              >{{ mode.safe ? "解鎖刪除" : "鎖定刪除" }}
              </b-button>
              <b-button
                :variant="mode.update ? 'primary' : 'success'"
                size="lg"
                @click="mode.update = !mode.update"
              >{{ mode.update ? "鎖定編輯" : "解鎖編輯" }}
              </b-button>
              <b-button
                size="lg"
                variant="dark"
                @click="refresh"
              >更新頁面
              </b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>
      <div class="col-lg-6 container-fluid">
        <b-row class="justify-content-between" style="margin: auto">
          <div>
            <h5>目前借用</h5>
          </div>
          <b-form-radio-group
            v-model="current.filter"
            :options="itemOptions"
            button-variant="outline-info"
            buttons
          />
        </b-row>
        <b-table
          id="currentTable"
          :current-page="current.currentPage"
          :fields="current.fields"
          :filter="filter"
          :filter-function="itemFilter"
          :filter-included-fields="filterOn"
          :items="current.records"
          :per-page="current.perPage"
          :tbody-tr-class="warn"
          bordered
          class="text-center"
          empty-filtered-text="沒有符合的紀錄"
          empty-text="目前沒有人借用物品"
          fixed
          hover
          show-empty
          striped
          @filtered="currentOnFiltered"
        >
          <template #cell(loanDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(room)="row">
            <template v-if="row.value">
              {{ row.item.dorm }}
              <br>
              {{ row.value + "-" + row.item.bed }}
            </template>
            <template v-else>
              非住宿生
            </template>
          </template>
          <template #cell(itemType)="row">
            {{ row.value }}
            <br>
            {{ row.item.itemName }}
          </template>
          <template #cell(action)="row">
            <b-btn-group size="sm" vertical>
              <b-button
                variant="info"
                @click="row.toggleDetails"
              >{{ row.detailsShowing ? "收起" : "展開" }}
              </b-button>
              <b-button
                :disabled="!mode.update"
                variant="secondary"
                @click="actions(row.item, 2)"
              >歸還
              </b-button>
            </b-btn-group>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>編號︰</strong>
                    {{ row.item.recordId }}
                  </b-card-text>
                </b-col>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>理由︰</strong>
                    {{ row.item.reason }}
                  </b-card-text>
                </b-col>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>次數︰</strong>
                    {{ row.item.count }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-if="current.records.length"
          v-model="current.currentPage"
          :per-page="current.perPage"
          :total-rows="current.rows"
          align="fill"
          aria-controls="currentTable"
          size="sm"
        />
      </div>
      <div class="col-lg-6 container-fluid">
        <b-row class="justify-content-between" style="margin: auto">
          <template>
            <h5>借用審核</h5>
          </template>
          <b-form-radio-group
            v-model="applying.filter"
            :options="itemOptions"
            button-variant="outline-info"
            buttons
          />
        </b-row>
        <b-table
          id="applyingTable"
          :current-page="applying.currentPage"
          :fields="applying.fields"
          :filter="filter"
          :filter-function="itemFilter"
          :filter-included-fields="filterOn"
          :items="applying.records"
          :per-page="applying.perPage"
          :tbody-tr-class="warn"
          bordered
          class="text-center"
          empty-filtered-text="沒有符合的紀錄"
          empty-text="目前沒有人申請借用"
          fixed
          hover
          show-empty
          striped
          @filtered="applyingOnFiltered"
        >
          <template #cell(room)="row">
            <template v-if="row.value">
              {{ row.item.dorm }}
              <br>
              {{ row.value + "-" + row.item.bed }}
            </template>
            <template v-else>
              非住宿生
            </template>
          </template>
          <template #cell(loanDate)="row">
            {{ row.value | formatDate }}
          </template>
          <template #cell(itemType)="row">
            <template v-if="mode.update && row.value !== '其他'">
              {{ row.value }}
              <b-form-input
                v-model="row.item.itemName"
                :class="['text-center', row.item.itemName.length > 0 ? '' : 'invalid']"
                :placeholder="row.value === '鑰匙' ? row.item.dorm + row.item.room + '-' + row.item.bed : row.value === '推車' ? 'ex: 1' : ''"
                maxlength="8"
                required
                size="sm"
                type="text"
              >
              </b-form-input>
            </template>
            <template v-else>
              {{ row.value === "其他" ? "" : row.value }}
              <br>
              {{ row.item.itemName }}
            </template>
          </template>
          <template #cell(credential)="row">
            <template
              v-if="mode.update"
            >
              <b-form-select
                v-model="row.item.credential"
                :class="['form-control', row.value.length > 0 ? '' : 'invalid']"
                :options="credentials"
                required
                size="sm"
              />
              <div class="invalid-tooltip">
                123
              </div>
            </template>
            <span v-else>{{ row.value }}</span>
          </template>
          <template #cell(comment)="row">
            <b-form-textarea
              v-if="mode.update"
              v-model="row.item.comment"
              :class="(row.item.credential !== '其他' || row.value.length > 0) ? '' : 'invalid'"
              :max-rows="3"
              maxlength="15"
              size="sm"
              type="text"
            />
            <span v-else>{{ row.value }}</span>
          </template>
          <template #cell(action)="row">
            <b-btn-group size="sm" vertical>
              <b-button
                variant="info"
                @click="row.toggleDetails"
              >{{ row.detailsShowing ? "收起" : "展開" }}
              </b-button>
              <b-button
                v-if="mode.safe"
                :disabled="!mode.update || check(row)"
                variant="primary"
                @click="actions(row.item, 1)"
              >審核
              </b-button>
              <b-button
                v-else
                variant="danger"
                @click="cancel(row.item.recordId)"
              >刪除
              </b-button>
            </b-btn-group>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>編號︰</strong>
                    {{ row.item.recordId }}
                  </b-card-text>
                </b-col>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>理由︰</strong>
                    {{ row.item.reason }}
                  </b-card-text>
                </b-col>
                <b-col class="sm-1">
                  <b-card-text>
                    <strong>次數︰</strong>
                    {{ row.item.count }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-if="applying.records.length"
          v-model="applying.currentPage"
          :per-page="applying.perPage"
          :total-rows="applying.rows"
          align="fill"
          aria-controls="applyingTable"
          size="sm"
        />
      </div>
    </b-row>
  </b-container>
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      search: {
        selected: null,
        options: [
          {text: "全部", value: null},
          {text: "學號", value: "studentId"},
          {text: "姓名", value: "name"},
          {text: "房號", value: "room"},
          {text: "物品", value: "itemType"},
          {text: "名稱", value: "itemName"},
          {text: "證件", value: "credential"},
          {text: "理由", value: "reason"},
          {text: "備註", value: "comment"}
        ],
        word: ""
      },
      credentials: [
        {text: "學生證", value: "學生證"},
        {text: "身份證", value: "身份證"},
        {text: "健保卡", value: "健保卡"},
        {text: "駕照", value: "駕照"},
        {text: "其他", value: "其他"}
      ],
      itemOptions: [
        {text: "全部", value: null},
        {text: "鑰匙", value: "鑰匙"},
        {text: "早離卡", value: "早離卡"},
        {text: "臨時卡", value: "臨時卡"},
        {text: "推車", value: "推車"},
        {text: "其他", value: "其他"}
      ],
      mode: {
        safe: true,
        update: false
      },
      filterOn: ["studentId", "itemType"],
      current: {
        filter: null,
        records: [],
        rows: 0,
        fields: [
          {key: "loanDate", label: "借用時間", tdClass: "align-middle"},
          {key: "studentId", label: "學號", tdClass: "align-middle"},
          {key: "name", label: "姓名", tdClass: "align-middle"},
          {key: "room", label: "房號", tdClass: "align-middle"},
          {key: "itemType", label: "物品", tdClass: "align-middle"},
          {key: "credential", label: "證件", tdClass: "align-middle"},
          {key: "comment", label: "備註", tdClass: "align-middle"},
          {key: "action", label: "", tdClass: "align-middle"}
        ],
        currentPage: 1,
        perPage: 5
      },
      applying: {
        filter: null,
        records: [],
        rows: 0,
        fields: [
          {key: "studentId", label: "學號", tdClass: "align-middle"},
          {key: "name", label: "姓名", tdClass: "align-middle"},
          {key: "room", label: "房號", tdClass: "align-middle"},
          {key: "itemType", label: "物品", tdClass: "align-middle"},
          {key: "credential", label: "證件", tdClass: "align-middle"},
          {key: "comment", label: "備註", tdClass: "align-middle"},
          {key: "action", label: "", tdClass: "align-middle"}
        ],
        currentPage: 1,
        perPage: 5
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get("/api/item/user", {params: {"current": true, "active": true}})
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const record = response.data[i];

          const data = {
            recordId: record["record_id"],
            studentId: record["student_id"],
            name: record["name"],
            dorm: record["dorm"],
            room: record["room"],
            bed: record["bed"],
            itemType: record["item_type"],
            itemName: record["item_name"],
            credential: record["credential"],
            reason: record["reason"],
            comment: record["comment"],
            loanDate: record["loan_date"],
            returnDate: record["return_date"],
            status: record["status"],
            count: record["count"]
          };
          if (data.status === "借用中")
            this.current.records.push(data);
          else if (data.status === "申請中") {
            if (data.itemType === "鑰匙" && !data.itemName)
              data.itemName = data.dorm + data.room + "-" + data.bed;
            this.applying.records.push(data);
          }
        }
        this.current.rows = this.current.records.length;
        this.applying.rows = this.applying.records.length;
      }).catch((error) => window.console.log(error));
    },
    check(row) {
      const data = row.item;
      const result = !data.itemName.trim() || !data.credential.trim();
      if ((data.itemType === "早離卡" || data.credential === "其他") || (data.itemType === "鑰匙" && data.itemName !== data.room)) {
        return result && !data.comment.trim();
      }
      return result;
    },
    nameCheck(name) {
      return name.length === 5 && /^[A-Z]$/i.test(name[0]);
    },
    refresh() {
      this.current.records = [];
      this.applying.records = [];
      this.mode.safe = true;
      this.getData();
    },
    actions(data, action) {
      if (!data.itemName || !data.credential) return;

      this.$bvModal.msgBoxConfirm("確定要" + (action === 1 ? "審核" : "歸還") + "嗎？", {
        title: '確認',
        okVariant: 'primary',
        okTitle: '確定',
        cancelTitle: '取消',
        centered: true
      })
      .then(value => {
        if (value) {
          let formData = new FormData();
          formData.append("action", action.toString());
          formData.append("item_name", data.itemName);
          formData.append("comment", data.comment);
          formData.append("credential", data.credential);
          axios.post("/api/item/verify/" + data.recordId, formData)
          .then(() => {
            window.location.reload();
            this.mounted();
          }).catch((error) => {
            if (error.response.status === 500)
              window.alert(error.response.data.msg);
            else
              window.alert(error);
          });
        }
      });
    },
    cancel(recordId) {
      if (this.mode.safe) return;

      this.$bvModal.msgBoxConfirm("確定要刪除嗎？", {
        title: '確認',
        okVariant: 'danger',
        okTitle: '確定',
        cancelTitle: '取消',
        centered: true
      })
      .then(value => {
        if (value) {
          axios.delete("/api/item/delete/" + recordId)
          .then(() => {
            window.location.reload();
            this.mounted();
          }).catch((error) => {
            if (error.response.status === 500)
              window.alert(error.response.data.msg);
            else
              window.alert(error);
          });
        }
      });
    },
    update(data) {
      let formData = new FormData();
      formData.append("record_id", data.recordId);
      formData.append("item_name", data.itemName);
      formData.append("comment", data.comment);
      axios.patch("/api/item/update", formData)
      .then(() => {
        window.alert("更改成功");
        window.location.reload();
        this.mounted();
      }).catch((error) => {
        if (error.response.status === 500)
          window.alert(error.response.data.msg);
        else
          window.alert(error);
      });
    },
    warn(item, type) {
      if (type !== "row") return;

      if (item.room === "") {
        return "table-danger";
      }

      const diff = new Date() - new Date(item.loanDate);
      if (item.status === "借用中") {
        if (item.itemType === "鑰匙" && diff / (1000 * 60) >= 10)
          return "table-danger";
        else if (item.itemType === "早離卡" && diff / (1000 * 60 * 60 * 24) >= 1)
          return "table-danger";
        else if (item.itemType === "臨時卡" && diff / (1000 * 60 * 60 * 24) >= 30)
          return "table-danger";
        else if (item.itemType === "推車" && diff / (1000 * 60 * 60) >= 1)
          return "table-danger";
      }
    },
    itemFilter(item, filter) {
      const index = item.status === "借用中" ? 1 : 2;
      if (this.search.selected === null) {
        for (const col in item) {
          if (typeof (item[col]) !== "string") item[col] = String(item[col]);
          const searchFlag = filter[0].length ? item[col].includes(filter[0]) : true;
          const typeFlag = filter[index] ? item.itemType === filter[index] : true;
          if (searchFlag && typeFlag)
            return true;
        }
        return false;
      }
      const searchFlag = filter[0].length ? item[this.search.selected].includes(filter[0]) : true;
      const typeFlag = filter[index] ? item.itemType === filter[index] : true;
      return searchFlag && typeFlag;
    },
    currentOnFiltered(items) {
      this.current.rows = items.length;
      if (items.length <= this.current.currentPage) {
        this.current.currentPage = 1;
      }
    },
    applyingOnFiltered(items) {
      this.applying.rows = items.length;
      if (items.length <= this.applying.currentPage) {
        this.applying.currentPage = 1;
      }
    }
  },
  computed: {
    filter() {
      return [this.search.word, this.current.filter, this.applying.filter];
    }
  },
  filters: {
    formatDate(str) {
      if (!str) return "X";
      const date = new Date(str).toISOString();
      return date.slice(5, 10) + " " + date.slice(11, 19);
    }
  }
}
</script>

<style scoped>
.invalid {
  border-color: red;
}
</style>
